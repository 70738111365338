import * as React from "react";
import { Link } from "gatsby";

const Breadcrumb = ({ links }) => {
  return (
    <ul
      class="breadcrumb"
      itemscope
      itemtype="https://schema.org/BreadcrumbList"
    >
      {links.map((link) => (
        <li
          itemprop="itemListElement"
          itemscope
          itemtype="https://schema.org/ListItem"
        >
          <Link to={link.href} itemprop="item">
            <span itemprop="name">{link.name}</span>
          </Link>
          <meta itemprop="position" content="1" />
        </li>
      ))}
    </ul>
  );
};

export default Breadcrumb;

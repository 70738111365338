import * as React from "react";
import "../styles/sass/main.scss";
import Layout from "../components/Layout";
import Breadcrumb from "../components/Breadcrumb";

const ContactPage = () => {
  let breadcrumbs = [
    { name: "Saad Wasil", href: "/" },
    { name: "Contact", href: "/contact" },
  ];
  let title = "Contact — Saad Wasil";
  let description = "A simple contact form to message Saad";
  return (
    <Layout pageTitle={title} description={description} navH1={0}>
      <div className="container contact">
        <header>
          <Breadcrumb links={breadcrumbs} />
          <h1>Contact</h1>
        </header>

        <main className="contact-content">
          <div className="contact-form">
            <form name="contact" method="POST" data-netlify="true">
              <div className="form-row">
                <div className="col">
                  <input
                    type="text"
                    className="name"
                    name="name"
                    placeholder="Name"
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="col">
                  <input
                    type="email"
                    className="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <textarea
                className="message"
                name="message"
                placeholder="Message"
                required
              ></textarea>

              <button type="submit" className="submit btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default ContactPage;
